import Header from "@components/Header/Header";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "@pages/Home/Home";
import Play from "@pages/Play/Play";
import PdfPresentation from "@pages/PdfPresentation/PdfPresentation";
import { useEffect } from "react";
import Loader from "@components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TxDataProvider } from "./contexts/TxDataContext";
import useTransactionToast from "./hooks/useTransactionToast";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invParam = params.get("inv");
    if (invParam && /^0x[a-fA-F0-9]{40}$/.test(invParam)) {
      localStorage.setItem("refAddress", invParam);
    }
  }, [location]);

  return (
    <TxDataProvider>
      <InnerApp />
    </TxDataProvider>
  );
}

const InnerApp = () => {
  useTransactionToast();

  return (
    <>
      <div className="App">
        <header className="App-header">
          <Loader />
        </header>
      </div>
      <ToastContainer />
    </>
  );
};

export default App;
