import React, { createContext, useContext, useState } from "react";

const TxDataContext = createContext({
  txData: {},
  updateData: () => {},
});

export const useTxData = () => {
  return useContext(TxDataContext);
};

export const TxDataProvider = ({ children }) => {
  const initialData = JSON.parse(localStorage.getItem("txData") || "{}");
  const [txData, setTxData] = useState(initialData);

  const updateData = (newData) => {
    setTxData(newData);
    if (newData.status && newData.status !== "handled") {
      localStorage.setItem("txData", JSON.stringify(newData));
    } else {
      localStorage.removeItem("txData");
    }
  };

  return (
    <TxDataContext.Provider value={{ txData, updateData }}>
      {children}
    </TxDataContext.Provider>
  );
};
