import {Route, Routes, Link, useParams, useLocation} from 'react-router-dom';
import logo from '@assets/images/header/logo.png'
import tgIcon from '@assets/images/header/tg-icon.svg'
import '@assets/scss/header/header.scss'
import { useState } from 'react';
import {ConnectButton} from "../Button/ConnectButton";
import {useAccount} from "wagmi";
import {ShareButton} from "../Button/ShareButton";
const Header = () => { 
    const [menuActive,setMenuActive] = useState();
    const [dropdownActive,setDropdownActive] = useState();
    const [dropdownActiveChat,setDropdownActiveChat] = useState();
    const menuClasess = `${menuActive ? 'header-nav__active' : ""} header-nav__list-wrapper`
    const burgerClasess = `${menuActive ? 'burger--active' : ""} burger`;
    const path = useLocation();
    const {isConnected} = useAccount()


    
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header__inner">
                        <nav className="header-nav header-nav__desktop">
                            <ul className="header-nav__list">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className='has-children' onClick={(e) => {
                                        setDropdownActive(!dropdownActive)
                                        e.stopPropagation()}}>
                                        <a href="#">Audit</a>
                                        {dropdownActive ? <ul className='submenu'>
                                            <li><Link to="https://solidproof.io" target='blank'>SolidProof Audit</Link></li>
                                            <li style={{border:"unset", background:"unset"}}><Link to="https://cryptoprim.com" target='blank'>CryptoPrim Audit</Link></li>

                                        </ul> : ""}
                                    </li>
                                <li>
                                    <Link to="/pdf">PDF Presentation</Link>
                                </li>
                            {/*} <li>
                                    <a href="#"><img src={tgIcon} alt="" />DappSide</a>
                                </li>*/}
                                
                            </ul>
                            <div className="header__logo">
                                <Link to="/">
                                <img src={logo} alt="" />
                                </Link>
                            </div>
                            

                            
                            <ul className="header-nav__actions">
                                <li>
                                    <a href="https://t.me/GoldDiggerChannel" target='blank'><img src={tgIcon} alt="" />Channel</a>
                                </li>
                                
                                {path.pathname !== "/play" &&


<li className='has-children' onClick={(e) => {
    setDropdownActiveChat(!dropdownActiveChat)
    e.stopPropagation()}}>
    <a href="#">Chat</a>
    {dropdownActiveChat ? <ul className='submenu'>
        <li><Link to="https://t.me/GoldDigger_Chat" target='blank'>Chat</Link></li>
        <li><Link to="https://t.me/GoldDigger_ENG_Chat" target='blank'>Eng Chat</Link></li>

    </ul> : ""}
</li>}
                                {path.pathname === "/play" && isConnected &&<li>
                                    <a href="https://t.me/GoldDigger_Chat" target='blank'><img src={tgIcon} alt="" />Chat</a>
                                </li>}
                                 
                                     {path.pathname !== "/play" ? <li><Link to="/play">Play</Link></li> : isConnected ?
                                      <li><ShareButton /></li> :
                               
                                      <li className='has-children' onClick={(e) => {
                                        setDropdownActiveChat(!dropdownActiveChat)
                                        e.stopPropagation()}}>
                                        <a href="#">Chat</a>
                                        {dropdownActiveChat ? <ul className='submenu'>
                                            <li><Link to="https://t.me/GoldDigger_Chat" target='blank'>Chat</Link></li>
                                            <li><Link to="https://t.me/GoldDigger_ENG_Chat" target='blank'>Eng Chat</Link></li>
                                    
                                        </ul> : ""}
                                    </li>
                               
                               }
                                
                                    {path.pathname === "/play" && <li>
                                         <ConnectButton />
                                </li>}
                            </ul>
                        </nav>
                        <nav className="header-nav header-nav__mobile">
                            <div className="header__logo">
                                
                                        {path.pathname !== "/play" ? <Link to="/"><img src={logo} alt="" /></Link> : isConnected ?
                                        <ShareButton /> :
                                        <Link to="/"><img src={logo} alt="" /></Link> }
                                   
                              {/*
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                            */}
                            </div>
                            <ul className="header-nav__list">
                                {path.pathname === "/play" && <li>
                                        <ConnectButton />
                                </li>}
                            </ul>
                            <div onClick={()=>setMenuActive(!menuActive)} className={burgerClasess}>
                                <span></span><span></span><span></span>
                            </div>
                            <div onClick={()=> setMenuActive(!menuActive)} className={menuClasess}>
                                <ul className="header-nav__list">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className='has-children' onClick={(e) => {
                                        setDropdownActive(!dropdownActive)
                                        e.stopPropagation()}}>
                                        <a href="#">Audit</a>
                                        {dropdownActive ? <ul className='submenu'>
                                            <li><Link to="https://solidproof.io" target='blank'>SolidProof Audit</Link></li>
                                        </ul> : ""}
                                    </li>
                                    <li>
                                        <a href="/pdf">PDF Presentation</a>
                                    </li>
                               {/*     <li>
                                        <a href="#"><img src={tgIcon} alt="" />DappSide</a>
                                    </li>*/}
                                </ul>
                                <ul className="header-nav__actions">
                                    <li>
                                        <a href="https://t.me/GoldDiggerChannel" target='blank'><img src={tgIcon} alt="" />Channel</a>
                                    </li>
                                    {path.pathname !== "/play" &&


                                    <li className='has-children' onClick={(e) => {
                                        setDropdownActiveChat(!dropdownActiveChat)
                                        e.stopPropagation()}}>
                                        <a href="#">Chat</a>
                                        {dropdownActiveChat ? <ul className='submenu'>
                                            <li><Link to="https://t.me/GoldDigger_Chat" target='blank'>Chat</Link></li>
                                            <li><Link to="https://t.me/GoldDigger_ENG_Chat" target='blank'>Eng Chat</Link></li>

                                        </ul> : ""}
                                    </li>}

                                    {path.pathname !== "/play" &&


<li className='has-children' onClick={(e) => {
    setDropdownActiveChat(!dropdownActiveChat)
    e.stopPropagation()}}>
    <a href="#">Chat</a>
    {dropdownActiveChat ? <ul className='submenu'>
        <li><Link to="https://t.me/GoldDigger_Chat" target='blank'>Chat</Link></li>
        <li><Link to="https://t.me/GoldDigger_ENG_Chat" target='blank'>Eng Chat</Link></li>

    </ul> : ""}
</li>}
                                    <li>
                                        <a href="/play">Play</a>
                                    </li>
                                   {/*
                                    <li>
                                        {path.pathname !== "/play" ? <Link to="/play">Play</Link> : isConnected ?
                                        <ShareButton /> :
                                        <a href="https://t.me/GoldDigger_Chat" target='blank'><img src={tgIcon} alt="" />Chat</a> }
                                    </li>
                                       {path.pathname === "/play" && <li>
                                            <ConnectButton />
                                    </li>}*/}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            
        </>
    );
}
 
export default Header;