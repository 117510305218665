import React from "react";
import ReactDOM from "react-dom/client";
import "@assets/scss/home-page/hero.scss";
import "@assets/scss/home-page/contract.scss";
import "@assets/scss/home-page/advantages.scss";
import "@assets/scss/home-page/deposit.scss";
import "@assets/scss/home-page/mine.scss";
import "@assets/scss/play-page/play-page.scss";
import "@assets/scss/footer/footer.scss";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

import { WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";

const root = ReactDOM.createRoot(document.getElementById("root"));

const projectId = "0c545ba2e8e9e368289b6d66265d2367";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeVariables: {
    "--w3m-color-mix": "#98551e",
    "--w3m-color-mix-strength": 30,
    // "--w3m-font-family": "Luckiest Guy",
    // "--w3m-font-size-master": "2",
  },
});

export function useShutTheFuckUpAboutENS() {
  useIsomorphicLayoutEffect(() => {
    const orig = window.console.error;
    window.console.error = function (...args) {
      if (args[0]?.name === "ChainDoesNotSupportContract") return;
      orig.apply(window.console, args);
    };
  }, []);
}

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);
