import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useWaitForTransaction, useNetwork } from "wagmi";
import { useTxData } from "../contexts/TxDataContext";

const useTransactionToast = () => {
  const { chain } = useNetwork();
  const { txData, updateData } = useTxData();

  // updateData({});

  const toastIdRef = useRef(null);

  const { status } = useWaitForTransaction({
    hash: txData?.hash,
    chainId: chain?.id,
  });

  const notify = (txStatus) => {
    if (txStatus === "loading") {
      toastIdRef.current = toast.loading("Transaction pending", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (txStatus === "success") {
      if (toastIdRef.current !== null) {
        toast.dismiss(toastIdRef.current);
      }
      toast.success("Transaction success!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (status && txData.status !== "handled") {
      notify(status);
      if (status === "success") {
        updateData({ ...txData, status: "handled" });
      }
    }
  }, [status]);
};

export default useTransactionToast;
