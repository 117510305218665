import {useAccount} from "wagmi";
import {useState} from "react";

export const ShareButton = () => {
    const {address} = useAccount()
    const [isCopied, setIsCopied] = useState(false)
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.hostname + "/play?inv=" + address)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 2000)
    }

    return (
        <button onClick={copyToClipboard} className="play-item__text">{isCopied ? "Copied" : "Share"}</button>
    )

}