import gold_mini from '@assets/images/gold-mini.svg';
import tether from '@assets/images/main-page/tether-crypto.svg';
import '@assets/scss/home-page/minepdf.scss';
import ru from '@assets/pdf/ru.pdf';
import en from '@assets/pdf/en.pdf';
import es from '@assets/pdf/es.pdf';
import ar from '@assets/pdf/ar.pdf';
import ch from '@assets/pdf/ch.pdf';

const Minepdf = () => {

    return ( 
        <>
        <section className="minepdf">
            <div className='pdfcontainer'>
                <div className="minepdf__items">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="minepdf__item">
                        <div className="minepdf__item__info">
                        <h3 className="minepdf__item__title">English</h3>
                        <a className="minepdf__item__btn" href={en} target = "_blank">view</a>
                        </div>
                    </div>
                </div>
                <div className="minepdf__items">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="minepdf__item">
                        <div className="minepdf__item__info">
                        <h3 className="minepdf__item__title">Spanish</h3>
                        <a className="minepdf__item__btn" href={es} target = "_blank">view</a>
                        </div>
                    </div>
                </div>
                <div className="minepdf__items">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="minepdf__item">
                        <div className="minepdf__item__info">
                        <h3 className="minepdf__item__title">Russian</h3>
                        <a className="minepdf__item__btn" href={ru} target = "_blank">view</a>
                        </div>
                    </div>
                </div>
                <div className="minepdf__items">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="minepdf__item">
                        <div className="minepdf__item__info">
                        <h3 className="minepdf__item__title">Arabian</h3>
                        <a className="minepdf__item__btn" href={ar} target = "_blank">view</a>
                        </div>
                    </div>
                </div>
                <div className="minepdf__items">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="minepdf__item">
                        <div className="minepdf__item__info">
                        <h3 className="minepdf__item__title">Chinese</h3>
                        <a className="minepdf__item__btn" href={ch} target = "_blank">view</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}
 
export default Minepdf;