import miner_mini from "@assets/images/miner-mini.svg";
import gold_mini from "@assets/images/gold-mini-star.svg";
import minerGif from "@assets/images/play-page/minergif.gif";
import pickaxe from "@assets/images/main-page/pickaxe-mini.svg";
import Modal from "@components/Modal/Modal";
import { useState,useEffect, Fragment } from "react";
import { useCalcInitMaxDeposit, useCheckReward, useUserInfo } from "../../contract/contract";
import { displayInfo } from "../../utils/displayInfo";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { getContract, getContractAddress, parseUnits } from "viem";
import ModalOne from "@components/Modal/ModalOne";
import ModalTwo from "@components/Modal/ModalTwo";
import { useSearchParams } from "react-router-dom";

const PlayPageHero = ({ getStars }) => {
  const getMiner = (count) => {

    if(count > 5) {
      count = 5
    }
    
    let miner = [];
    for (let i = 0; i < count; i++) {
      miner.push(<img key={i} src={minerGif} alt="" />);
    }
    return miner;
  };



  const [modalActive, setModalActive] = useState(false);
  const [modalOneActive, setModaOnelActive] = useState(false);
  const [modalTwoActive, setModalTwoActive] = useState(false);

  const [number, setNumber] = useState(0);


  const { data: userInfo, isLoading, isConnected, isError } = useUserInfo();
  var calcInitMaxDeposit = useCalcInitMaxDeposit();
  

  
  if(calcInitMaxDeposit == 0) {
    calcInitMaxDeposit = 50n
  }


  
  const [ref] = useAutoAnimate();

  // const qtyMiner = userInfo[0] / parseUnits("0.05", 18);

  // console.log(calcInitMaxDeposit + 'calcInit')

    // console.log(userInfo);

    // console.log("resis" + ( Number(userInfo[8]) / 100))

  //   console.log("calc result is " + userInfo[0] / parseUnits("0.05", 18) )

  // var qtyMiner = userInfo[0] / parseUnits("0.05", 18)

  // if(qtyMiner >=5) {
  //   qtyMiner = 5
  // }

  // console.log("current qtyMiner" + qtyMiner)

  const calcCheckReward = useCheckReward();
  // console.log("getMinerCount" + Number(userInfo[0] / 50000000000000000n))



  // console.log(calcCheckReward)
  
  
  useEffect(() => {
    // console.log(calcInitMaxDeposit + 'is calcdepo')

    if (!userInfo) {
      // console.log("cannot detect userInfo")
      return
      
    }
    // console.log(userInfo)
    // console.log(typeof(userInfo[0]) + "typeof")

    
    const startNumber = Number(userInfo[4] + calcCheckReward) ;

    const increment =  Number((((userInfo[0]/100n*userInfo[12])/10000000000n)/60n));
 
  

    const duration = 10000000000000; // infinity duration
    const frames = duration / (1000 / 60); // Number of frames based on 60fps

    let frame = 0;
    const interval = setInterval(() => {
      frame++;
      // console.log("frame is " + frame)

      const value = (startNumber + increment * frame)/1000000000000000  ;
      
      
      setNumber(value.toFixed(6));

      // if (frame >= frames) {
      //   clearInterval(interval);
      //   setNumber(targetNumber);
      // }
    }, duration / frames);

    return () => clearInterval(interval);
  }, [userInfo]);

  // console.log(calcInitMaxDeposit + "after UseEffect")

  return (

    
    <>
      <section className="play-hero">
        <div ref={ref} className="container">
          <div className="miner">
            {userInfo && getMiner(Number(userInfo[0] / 50000000000000000n))}
          </div>
          {isConnected && !isLoading && !isError && userInfo && (
            <div className="play-hero__items">
              <div className="play-hero__item">
                <div className="play-hero__item__plantation play-plantation">
                  <p>Miners pcs</p>
                  <div className="play-plantation__statistics">
                    <span>
                      {displayInfo(
                        Number(userInfo[0] / 50000000000000000n),
                        isLoading
                      )}{" "}
                    </span>
                    <p style={{ fontSize: "30px" }}>
                      ={" "}
                      {displayInfo(
                        Number(userInfo[0] / 1000000000000000n) / 1000,
                        isLoading
                      )}{" "}
                      BNB
                    </p>
                  </div>
                </div>
                <div className="play-hero__item__info play-items">
                  <img className="play-items__img" src={miner_mini} alt="" />
                  <div className="play-items__wrapper">
                    <div
                      onClick={() => {
                        document.body.classList.add("modal-open");
                        setModaOnelActive(true);
                      }}
                      className="play-item"
                    >
                      <p className="play-item__text" style={{ animation: 'increaseNumber 1s' }}>
                        {/* {displayInfo(
                          Number(userInfo[4]) / 1000000000000000,
                          isLoading
                        ).toFixed(6)}

                            + 
                          {displayInfo(
                          Number(calcCheckReward,
                          isLoading).toFixed(5))} */}

                  {number}

                      </p>
                      <div className="play-item__img"></div>
                    </div>
                    <div
                      onClick={() => {
                        document.body.classList.add("modal-open");
                        setModalTwoActive(true);
                      }}
                      className="play-item"
                    >
                      <p className="play-item__text">
                        {displayInfo(
                          Number(userInfo[5] / 10000000000000000n),
                          isLoading
                        )}
                      </p>
                      <div className="play-item__img"></div>
                    </div>
                    <div
                      onClick={() => {
                        document.body.classList.add("modal-open");
                        setModalActive(true);
                      }}
                      className="play-item"
                    >
                      <p className="play-item__text">Hire Miners</p>
                      <div className="play-item__img"></div>
                    </div>
                  </div>
                </div>
                <div className="play-hero__item__info play-earned">
                  <p>Mined Gold</p>
                  <div className="play-earned__statistics">
                    <span>    {displayInfo(
                          Number(userInfo[3]) / 1000000000000000,
                          isLoading
                        ).toFixed(2)}</span>
                    <p>
                      {displayInfo(
                         parseFloat((Number(userInfo[8]) / 100 + 2.76).toFixed(2)),
                        isLoading
                      )}{""}%                     daily
                    </p>
                  </div>
                </div>
              </div>
              <div className="play-hero__maturation">
                <div className="play-hero__maturation__text">
                  <p>Maturation</p>
                  <span>
                    {Number(
                      userInfo[0] / 100000000000000n / calcInitMaxDeposit
                    )}
                    %
                  </span>
                </div>
                {calcInitMaxDeposit && (
                  <div className="play-hero__maturation__progressbar play-progressbar">
                    <div
                      style={{
                        width: `${Number(
                          userInfo[0] / 100000000000000n / calcInitMaxDeposit
                        )}%`,
                      }}
                      className="play-progressbar__item"
                    >
                      {getStars(6)}
                      <img src={gold_mini} alt="" className="gold" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal active={modalActive} setActive={setModalActive} />
      <ModalOne active={modalOneActive} setActive={setModaOnelActive} />
      <ModalTwo active={modalTwoActive} setActive={setModalTwoActive} />
    </>
  );
};

export default PlayPageHero;
