import modalClose from "@assets/images/close-svg.svg";
import { useState, useEffect } from "react";
import {
  useWithdraw,
  useReinvestGold,
  useCheckReward,
  useUserInfo,
} from "../../contract/contract";
import "@assets/scss/modal/modal.scss";
import { formatUnits, parseUnits } from "viem";
import { toast } from "react-toastify";

const Modal = ({ active, setActive }) => {
  const [amount, setAmount] = useState(0);
  const [gold, setGold] = useState(0);

  const {
    data: userBaseInfo,
    isLoadingBaseInfo,
    isConnected,
    balance,
  } = useUserInfo();

  const {
    isLoading: isLoadingW,
    isError: isErrorW,
    isSuccess: isSuccessW,
    write: withdraw,
  } = useWithdraw();

  const {
    isLoading: isLoadingR,
    isError: isErrorR,
    isSuccess: isSuccessR,
    write: reinvestGold,
  } = useReinvestGold();

  const handleWithdraw = (e) => {
    e.preventDefault();
    if (!amount) return toast.warning("Please enter amount");
   
    withdraw(amount);
  };

  const handleReinvestGold = (e) => {
    e.preventDefault();
    if (!amount) return toast.warning("Please enter amount");
    // console.log("got amount in reinvestGold" + amount)
    reinvestGold(amount);
  };

  useEffect(() => {
    if (userBaseInfo) {
      setGold(Number(userBaseInfo[4]));
    }
  }, [userBaseInfo]);

  const { data: userInfo, isLoading,  isError } = useUserInfo();

  const [newGold, setNewGold] = useState(0);
  const calcCheckReward = useCheckReward();


  useEffect(() => {
    

    
    if (!userInfo) {
      return
    }

    
    const startNumber = Number(userInfo[4] + calcCheckReward) ;

    const increment =  Number((((userInfo[0]/100n*userInfo[12])/10000000000n)/60n));
 
  

    const duration = 10000000000000; // infinity duration
    const frames = duration / (1000 / 60); // Number of frames based on 60fps

    let frame = 0;
    const interval = setInterval(() => {
      frame++;
      // console.log("frame is " + frame)

      const value = (startNumber + increment * frame)/1000000000000000  ;
      
      setNewGold(value.toFixed(6));

      // if (frame >= frames) {
      //   clearInterval(interval);
      //   setNumber(targetNumber);
      // }
    }, duration / frames);

    return () => clearInterval(interval);
  }, [userInfo]);


  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("modal-open");
          setActive(false);
        }}
        className={active ? "modal__wrapper active" : "modal__wrapper"}
      >
        <div onClick={(e) => e.stopPropagation()} class="modal">
          <div className="modal__top">
            <div class="modal__title">
              <span>Manage balance</span>{" "}
            </div>
            <div
              onClick={() => {
                document.body.classList.remove("modal-open");
                setActive(false);
              }}
              class="modal__close"
            >
              <img src={modalClose} alt="" />
            </div>
          </div>
          <div class="modal__body">
            <table>
              <tbody>
                <tr>
                  <td>Available</td>
                  <td className="d-flex-m-auto">
                    {/* <span>{(gold/1000000000000000).toFixed(6)} gold bars</span> */}
                    {/* <span>{(newGold/1000000000000000).toFixed(6)} gold bars</span> */}

                    <span>{newGold} gold bars</span>

{/* 

                    {displayInfo(
                          Number(userInfo[4]) / 1000000000000000,
                          isLoading
                        ).toFixed(6)} */}


                  </td>
                </tr>
              </tbody>
            </table>
            <form>
              <input
                type="number"
                min={0}
                // value={amount}
                onChange={(e) => setAmount(e.target.value * 1000000000000000 )}
              />
              <div className="flex-button">
                <button
                  // type="submit"
                  onClick={handleWithdraw}
                >
                  Withdraw
                </button>
                <button
                  //   type="submit"
                  onClick={handleReinvestGold}
                  className="m-left"
                >
                  Reinvest Gold
                </button>
              </div>
            </form>
            <div className="modal__body__footer">
              <p>
                The withdrawal of profits is instantaneous.
              </p>
              <h2></h2>
              <p className="p-top">
                To withdraw your income, specify the desired value and click
                "Withdraw" button
              </p>
              <p className="p-top">
                The maximum withdrawal of funds is unlimited. Profit withdrawal
                is available every day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
