import modalClose from "@assets/images/close-svg.svg";
import { useState, useEffect } from "react";
import { useReinvest, useUserInfo } from "../../contract/contract";
import "@assets/scss/modal/modal.scss";
import { toast } from "react-toastify";

const Modal = ({ active, setActive }) => {
  const [amount, setAmount] = useState(0);
  const [pickaxe, setPickaxe] = useState(0);

  const { isLoading, isError, isSuccess, write } = useReinvest();

  const {
    data: userBaseInfo,
    isLoadingBaseInfo,
    isConnected,
    balance,
  } = useUserInfo();

//   console.log(userBaseInfo);

  const handleReinvest = (e) => {
    e.preventDefault();
    // console.log("current reinvest amount is " + amount)
    if (!amount) return toast.warning("Please enter amount");
    write(amount);
  };

  useEffect(() => {
    if (userBaseInfo) {
      setPickaxe(Number(userBaseInfo[5]));
    }
  }, [userBaseInfo]);

  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("modal-open");
          setActive(false);
        }}
        className={active ? "modal__wrapper active" : "modal__wrapper"}
      >
        <div onClick={(e) => e.stopPropagation()} class="modal">
          <div className="modal__top">
            <div class="modal__title">
              <span>Reinvestment</span>{" "}
            </div>
            <div
              onClick={() => {
                document.body.classList.remove("modal-open");
                setActive(false);
              }}
              class="modal__close"
            >
              <img src={modalClose} alt="" />
            </div>
          </div>
          <div class="modal__body">
            <table>
              <tbody>
                <tr>
                  <td>Available</td>
                  <td className="d-flex-m-auto">
                    <span>{pickaxe/10000000000000000} Pickaxes</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <form>
              <input
                type="number"
                min={0}
                // value={amount}
                onChange={(e) => setAmount(e.target.value * 10000000000000000)}
              />
              <div>
                <button onClick={handleReinvest}>Reinvest</button>
              </div>
            </form>
            <div className="modal__body__footer">
              <p>
                The withdrawal of profits is instantaneous.
              </p>
              <h2></h2>
              <p className="p-top">
                To withdraw your income, specify the desired value and click
                "Withdraw" button
              </p>
              <p className="p-top">
                The maximum withdrawal of funds is unlimited. Profit withdrawal
                is available every day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
