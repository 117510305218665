import modalClose from "@assets/images/close-svg.svg";
import "@assets/scss/modal/modal.scss";
import { useReducer, useState, useEffect } from "react";
import {
  useDeposit,
  useUserInfo,
  useCalcInitMaxDeposit,
} from "../../contract/contract";
import { formatUnits, parseUnits } from "viem";
import { toast } from "react-toastify";
import { getParseTreeNode } from "typescript";

const isZeroAddress = (address) => {
  return /^0x0+$/.test(address);
};

const isEthereumAddress = (address) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

async function frontIsZeroAddress(address)  {
  return /^0x0+$/.test(address);

}

const Modal = ({ active, setActive }) => {
  const [depositAmount, setDepositAmount] = useState("");
  const [partnerAddress, setPartnerAddress] = useState(
    "0xaAb5cf19B0D0002221C5723c5616a09F434fc036"
  );
  const [userDeposit, setUserDeposit] = useState(0);

  const {
    data: userBaseInfo,
    isLoadingBaseInfo,
    isConnected,
    balance,
  } = useUserInfo();

  var isPartnerExists;

  const partnerAddressFromUrl = new URLSearchParams(window.location.search).get(
    "inv"
  );
  const { data: txData, isLoading, isError, isSuccess, write } = useDeposit();
  //   const { data } = useCalcInitMaxDeposit();

  const maxDeposit = useCalcInitMaxDeposit();

  //   console.log(maxDeposit + " is calc deposit data");

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(e.body + 'is e')
  
    // const initalFrontAddress = partnerAddress;

    if (!depositAmount) return toast.warning("Please enter deposit amount");

    if (
      userDeposit && depositAmount && userDeposit + parseUnits(depositAmount, balance.decimals) >
        maxDeposit * 10000000000000000n
    ) {
      return toast.warning("Too much deposit value for now");
    }





    
    // if(partnerAddressFromUrl) {
    //   setPartnerAddress(partnerAddressFromUrl)
    // }
    
    // if(isZeroAddress(partnerAddress)) {
    //   setPartnerAddress("0xaAb5cf19B0D0002221C5723c5616a09F434fc036")
    // }

    // if (
  
    //   !isEthereumAddress(partnerAddress)
    // ) {
    //   return toast.warning("Please enter correct partner address");

    // }

    // console.log("partner address is " + partnerAddress)


    if(isZeroAddress(partnerAddress)) {
      return  toast.warning("Could not set zero address");

    }


    if(!isEthereumAddress(partnerAddress)) {
      return  toast.warning("Please enter correct partner address");

    }


    write(
      partnerAddress,
      parseUnits(depositAmount, balance.decimals)
    );

    

    // if(!isZeroAddress(partnerAddress) && isEthereumAddress(partnerAddress) ) {

   
  
    // }


    // write(
    //   partnerAddressFromUrl || partnerAddress,
    //   parseUnits(depositAmount, balance.decimals)
    // );
  };

  var getPercentage = 321600;
  // useEffect(() => {

    
  //   if (userBaseInfo) {

  //     if(userBaseInfo[7]) {
  //       isPartnerExists = true;
  //     }

  //     setUserDeposit(userBaseInfo[0]);

  //     if(partnerAddressFromUrl) {
  //       setPartnerAddress(partnerAddressFromUrl); 

  //     }
  //      getPercentage = userBaseInfo[12]
  //   }
  // }, [userBaseInfo]);

  useEffect(() => {
    const storedAddress = localStorage.getItem('refAddress');
    if (storedAddress) {
      setPartnerAddress(storedAddress);
    }
  }, []);

  // console.log(balance.value + 'is balance')

  // useEffect(() => {
  //   if (
  //     userDeposit &&
  //     depositAmount &&
  //     userDeposit + parseUnits(depositAmount, balance.decimals) >
  //       maxDeposit * 10000000000000000n
  //   ) {
  //     toast.warning("Too much deposit value for now");
  //   }
  // }, [depositAmount]);

  return (
    <>
      <div
        onClick={() => {
          document.body.classList.remove("modal-open");
          setActive(false);
        }}
        className={active ? "modal__wrapper active" : "modal__wrapper"}
      >
        <div onClick={(e) => e.stopPropagation()} class="modal">
          <div className="modal__top">
            <div class="modal__title">
              <span>Deposit</span>
            </div>
            <div
              onClick={() => {
                document.body.classList.remove("modal-open");
                setActive(false);
              }}
              class="modal__close"
            >
              <img src={modalClose} alt="" />
            </div>
          </div>
          <div class="modal__body">
            <table>
              {!balance || !userBaseInfo ? (
                <p>Loading...</p>
              ) : isError ? (
                <p>Error</p>
              ) : (
                <tbody>
                  <tr>
                    <th></th>
                    <th>Now</th>
                    <th>Will be</th>
                  </tr>
                  <tr>
                    <td>Wallet balance</td>
                    <td>
                      <span>{`${(+balance?.formatted).toFixed(4)} BNB`}</span>
                    </td>
                    <td>
                      <span>
                        {formatUnits(
                          balance?.value -
                            parseUnits(depositAmount, balance.decimals),
                          balance.decimals
                        )
                          .toString()
                          .slice(0, -14)}{" "}
                        BNB
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Deposit</td>
                    <td>
                      <span>
                        {(formatUnits(userBaseInfo[0], balance.decimals)).slice(0,-14)}{" "}
                        BNB
                      </span>
                    </td>
                    <td>
                      <span>
                        {(formatUnits(
                          userBaseInfo[0] +
                            parseUnits(depositAmount, balance.decimals),
                          balance.decimals
                        )).slice(0,-14)}{" "}
                        BNB
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Daily profit</td>
                    <td>
                      <span>
                        {" "}
                        {(
                          ((((Number(userBaseInfo[0]) / 100) *
                            Number(userBaseInfo[12])) /
                            10000000000) *
                            86400) /
                          1000000000000000000
                        ).toFixed(5)}{" "}
                        BNB
                      </span>
                    </td>
                    <td>
                      <span>
                        {(
                          ((((Number(
                            userBaseInfo[0] +
                              parseUnits(depositAmount, balance.decimals)
                          ) /
                            100) *
                            Number(

                            (getPercentage != 0 ) ? getPercentage: 321600

                            )) /
                            10000000000) *
                            86400) /
                          1000000000000000000
                        ).toFixed(5)}{" "}
                        BNB
                      </span>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
            <form>
              <p>Amount</p>
              <input
                type="number"
                onChange={(event) => setDepositAmount(event.target.value)}
                // value={depositAmount}
              />

          
              {userBaseInfo   &&  isZeroAddress(userBaseInfo[7]) &&  (
                <>
                  <p>Partner address</p>
                  <input
                    type="text"
                    onChange={(event) => setPartnerAddress(event.target.value)}
                    value={partnerAddress}
                  />
                </>
              )}

              <button onClick={handleSubmit}>
                {isLoading
                  ? "Depositing..."
                  : isError
                  ? "Something went wrong"
                  : "Deposit"}
              </button>
            </form>
            <div className="modal__body__footer">
              <p>
                The minimum amount is 0.05 BNB. 
                {/* The maximum total
                amount is {maxDeposit} BNB && isZeroAddress(userBaseInfo[7]) */ }
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
