import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
} from "wagmi";
import abi from "../contract_abi.json";
import { useTxData } from "../contexts/TxDataContext";
import { useEffect } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

const contract = {
  address: "0xc7ef12b20a9f02fabe9cfa7cc46917469068f478",
  abi,
};


// mainnet contract 0xA811832F13818FaA30531Dc4DCf55649B53e9103



// test bsc 0x32765F60BfB7C1154DAAf81c99B929d14d2C2c4E


export function useShutTheFuckUpAboutENS() {
  useIsomorphicLayoutEffect(() => {
    const orig = window.console.error;
    window.console.error = function (...args) {
      if (args[0]?.name === "ChainDoesNotSupportContract") return;
      orig.apply(window.console, args);
    };
  }, []);
}

// new contract 0x2C2C8D2d55EC44c6c5A01351A4dde9f8F6213437

// old contract 0x21C6d9a940c312d5aE026c428e9EBa6F8d9306E4

export const useUserInfo = () => {
  const { address, isConnected } = useAccount();

  let {
    data: balance,
    isLoading: isLoadingBalance,
    error: errorBalance,
  } = useBalance({ address });

  let {
    data,
    isError,
    isLoading,
    error: errorRead,
  } = useContractRead({ ...contract, functionName: "user", args: [address] });
  return {
    data,
    isError,
    isLoading,
    errorRead,
    balance,
    isLoadingBalance,
    errorBalance,
    isConnected,
  };
};

export const useCalcInitMaxDeposit = () => {
  let {
    data,
    isError,
    isLoading,
    error: errorRead,
  } = useContractRead({ ...contract, functionName: "_calcInitMaxDeposit" });
  return data;
};


export const useCheckReward = () => {
  const { address, isConnected } = useAccount();

  let {
    data,
    isError,
    isLoading,
    error: errorRead,
  } = useContractRead({ ...contract, functionName: "checkReward", args: [address] });
  return data;
};


export const useDeposit = () => {
  const { write, data, isSuccess, isLoading, isError, error } =
    useContractWrite({
      ...contract,
      functionName: "HireMiner",
    });

  const { updateData } = useTxData();
  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data]);

  return {
    write: (partnerAddress, value) => write({ args: [partnerAddress], value }),
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useWithdraw = () => {
  const { write, data, isSuccess, isLoading, isError, error } =
    useContractWrite({
      ...contract,
      functionName: "Withdraw",
    });

  const { updateData } = useTxData();
  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data]);

  return {
    write: (value) => write({ args: [value] }),
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useReinvestGold = () => {
  const { write, data, isSuccess, isLoading, isError, error } =
    useContractWrite({
      ...contract,
      functionName: "ReinvestGold",
    });

  const { updateData } = useTxData();
  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data]);

  return {
    write: (value) => write({ args: [value] }),
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};

export const useReinvest = () => {
  const { write, data, isSuccess, isLoading, isError, error } =
    useContractWrite({
      ...contract,
      functionName: "Reinvest",
    });

  const { updateData } = useTxData();

  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data]);

  return {
    write: (value) => write({ args: [value] }),
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};
